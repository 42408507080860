@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
/* masonry grid */
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    flex-direction: row;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
}
